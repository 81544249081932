.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(01, 08, 28, 0.7);
    color: var(--primary-white);
    padding: 0 5%;
    height: auto;
    position: absolute;
    width: 90%;
    z-index: 1;
    transition: background-color 0.3s;

    & .feller-logo {
        height: 100px;
        padding: 20px 0px;
    }

    & .menu {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: space-around;
        height: 100%;
        gap: 10px;


        & .contact {
            display: flex;
            justify-content: flex-end;
            gap: 20px;
            align-items: center;

            & input {
                height: 36px;
            }

            & #buttons {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 20px;

                span {
                    font-size: 16px;
                    color: var(--primary-white);
                    cursor: pointer;
                }

                .login {
                    a {
                        cursor: pointer;
                        font-size: 16px;
                        color: var(--primary-gold);
                    }
                }

            }
        }

        & .navigation {
            display: flex;
            grid-template-columns: auto;
            gap: 50px;
            list-style-type: none;
            font-size: 15px;
            background-color: transparent;

            & .menu-item.mobile{
                display: none;
            }

            & .sub-menu {
                display: none;
                font-size: 13px;
                list-style-type: none;
                position: absolute;
                text-align: start;
                margin: 10px 0px 0px 0px;
                padding: 0px 10px 0px 0px;

                & li {
                    padding: 10px 0px;
                    border-radius: 10px;
                    background-color: var(--submenu-primary-background);

                    & a {
                        color: var(--primary-blue);
                        font-weight: 600;
                        font-size: 11px;
                        margin: 0px 10px;
                    }

                    & a:hover {
                        color: var(--primary-blue);
                        border-bottom: 2px solid var(--primary-blue);
                    }
                }

                & li:nth-last-child(1) {
                    border: none;
                }
            }

            & li {
                padding: 10px 0px;

                & a {
                    color: var(--primary-white);
                    text-decoration: none;
                    cursor: pointer;
                }

                & label {
                    font-size: 15px;
                    color: var(--primary-white);
                    text-decoration: none;
                    cursor: pointer;
                }

                & a:hover,
                label:hover {
                    color: var(--primary-gold);
                    border-bottom: 1px solid var(--primary-gold);
                }

                & input {
                    display: none;
                }
            }

            & li:hover {
                & .sub-menu {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                }
            }
        }
    }

    &.fixed {
        position: fixed;
        background-color: rgba(1, 8, 28, 1);
        z-index: 2;
        height: 80px;

        & .feller-logo {
            height: 60px;
        }

        & .menu {
            gap: 0px;

            .contact #buttons {
                display: none;
            }
        }
    }

    & .navbar-toggler {
        display: none;
        height: min-content;
        cursor: pointer;

        & .bar {
            width: 40px;
            height: 5px;
            margin-bottom: 10px;
            background: var(--primary-white);
            border-radius: 10%;
            transition: .4s;
        }
    }
}

@media (max-width:1000px) {
    .header {
        background-color: rgba(01, 08, 28, 1);
        height: 110px;
        z-index: auto;

        & .feller-logo {
            height: 60px;
        }

        & .menu {

            & .contact {
                display: none;
            }

            & .navigation {
                display: grid;
                position: absolute;
                background-color: rgba(01, 08, 28, 1);
                width: 100%;
                top: 90px;
                left: 0;
                gap: 0px;
                transform: translateY(-100%);
                z-index: -1;
                transition: .6s;
                padding: 0px 0px 30px 0px;

                & .menu-item.mobile{
                    display: list-item;
                }

                & .sub-menu {
                    display: none;
                    background-color: rgba(01, 08, 28, 1);
                    border-bottom-left-radius: 0px;
                    border-top-right-radius: 0px;

                    & li {
                        background-color: rgba(01, 08, 28, 1);

                        & a {
                            color: var(--primary-white);
                            font-size: 12px;
                        }

                        & a:hover {
                            color: var(--primary-white);
                            border-bottom: 2px solid var(--primary-white);
                        }
                    }
                }

                & li {
                    display: block;
                    padding: 20px 0px 0px 20px;

                    & a:hover,
                    label:hover {
                        color: var(--primary-white);
                        border-bottom: none;
                    }

                    & input {
                        display: none;
                    }

                    & label {
                        display: inline-block;
                    }

                    & input[type="checkbox"]:checked+label::after,
                    label {
                        content: "-";
                    }

                    & input[type="checkbox"]+label::after {
                        content: "+";
                        font-size: 15px;
                        padding-left: 5px;
                    }

                    & input[type="checkbox"]:checked~.sub-menu {
                        display: block;
                    }
                }

                & li:hover .sub-menu {
                    display: none;
                }

                & .sub-menu {
                    position: relative;
                    text-align: start;
                    margin: 0px 0px 0px 1rem;
                    padding: 0;
                }

                & input:checked~.sub-menu {
                    display: block;
                }

            }
        }

        &.fixed {
            position: absolute;
            z-index: auto;
            height: 110px;
        }

        & .navbar-toggler {
            display: grid;
            z-index: 3;
        }
    }
}

.toggler-checkbox {
    display: none;
}

input:checked~.menu {
    & .navigation {
        transform: translateY(0);
        z-index: 3;
    }
}

img{
    &.feller-logo {
        z-index: 3;
    }
    
}

input:checked~.navbar-toggler .bar:nth-child(1) {
    position: absolute;
    transform: rotate(45deg);
}

input:checked~.navbar-toggler .bar:nth-child(2) {
    display: none;
}

input:checked~.navbar-toggler .bar:nth-child(3) {
    transform: rotate(-45deg);
}